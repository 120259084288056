<template>
  <div class="cz">
    <van-field
      class="tel"
      v-model="phone"
      placeholder="请输入手机号码"
      type="tel"
      maxlength="11"
    />
    <div class="lable">
      充话费
      <span v-if="zk">({{ zk }}折)</span>
    </div>
    <van-grid :column-num="3" clickable :gutter="10">
      <van-grid-item
        @click="startCz(item)"
        v-for="(item, index) in list"
        :key="`cz_${index}`"
      >
        <div class="item">
          <div>{{ item.price }}元</div>
        </div>
      </van-grid-item>
    </van-grid>
    <div class="tips">
      1、话费慢充为1-72小时到账<br />
      2、空号错号无售后。<br />
      3、不支持携号转网号码、虚拟运营商号段、集团代充值号码、副卡充值。<br />
      4、充值前请保证号码没有停机，停机号码可能无法到账。
    </div>
  </div>
</template>
<script>
import { Dialog, Toast } from "vant";
import api from "@api";
export default {
  data() {
    return {
      roleData: JSON.parse(localStorage.getItem("roleData")),
      phone: "",
      zk: 0,
      list: [
        { price: 50, money: 50 },
        { price: 100, money: 100 },
        { price: 200, money: 200 }
      ]
    };
  },
  mounted() {
    this._getCurrentDiscount();
  },
  methods: {
    // 调用充值
    startCz(item) {
      if (!this.phone) {
        Toast("请输入手机号码！");
        return;
      }

      Dialog.confirm({
        title: "提示",
        message: "请检查号码是否无误!"
      })
        .then(() => {
          // 点击确认,调用微信支付进行充值
          api
            .rechargePhoneOrder({
              userId: this.roleData.userPO.id,
              phone: this.phone,
              rechargeMoney: item.price,
              rechargeType: "0",
              payType: "0"
            })
            .then(res => {
              let {
                appId,
                nonceStr,
                paySign,
                signType,
                timeStamp
              } = res.response;
              if (res.meta.code == 200) {
                this.$wx.chooseWXPay({
                  appId,
                  timestamp: timeStamp,
                  nonceStr,
                  package: res.response.package,
                  signType,
                  paySign,
                  success: data => {
                    // 支付成功后的回调函数
                    // console.log(11111111, data)
                  }
                });
              } else {
                Toast(res.meta.message);
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 获取折扣
    _getCurrentDiscount() {
      api.getCurrentDiscount().then(res => {
        this.zk = res.response * 100;
      });
    }
  }
};
</script>
<style lang="less" scoped>
.cz {
  width: 100vw;
  height: 100vh;
  padding: 0 0.625rem;
  box-sizing: border-box;
  color: #666;
  .tel {
    border-bottom: 0.0625rem solid #eee;
    margin-bottom: 0.625rem;
    font-size: 18px;
    font-weight: bold;
  }
  .lable {
    font-size: 0.75rem;
    margin-bottom: 0.625rem;
  }
  .item {
    font-size: 0.625rem;
    text-align: center;
    // vertical-align: middle;
    color: #42b983;
    & > div:first-child {
      font-weight: bold;
      // margin-bottom: .625rem;
    }
  }
  /deep/.van-grid-item__content--surround::after {
    border-width: 0.0625rem;
    border-color: #42b983;
  }
  .tips {
    margin-top: 30px;
    font-size: 0.625rem;
    color: #999;
    line-height: 1.125rem;
  }
}
</style>
